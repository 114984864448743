import React from 'react';
import DataWrapChart from '../datawrap';

function DemTrends() {
  return (
    <section className="usa-graphic-list usa-section">
      <div className="grid-container">
        <a name="#dem_break"></a> <h1>Demographic Breakdown</h1>
        <div>
          <hr></hr>
        </div>
      </div>

      <div className="grid-container"></div>
      <div className="grid-container">
        <DataWrapChart
          frameborder="0"
          aria-label="Table"
          id="datawrapper-chart-Fucs6"
          height="500"
          allowfullscreen="true"
          src="https://datawrapper.dwcdn.net/MGzVl/"
        />
      </div>

      <div className="grid-container">
        <DataWrapChart
          frameborder="0"
          aria-label="Table"
          id="datawrapper-chart-Fucs6"
          height="500"
          allowfullscreen="true"
          src="https://datawrapper.dwcdn.net/TGCSh/"
        />
      </div>

      <div className="grid-container">
        <DataWrapChart
          frameborder="0"
          aria-label="Table"
          id="datawrapper-chart-Fucs6"
          height="500"
          allowfullscreen="true"
          src="https://datawrapper.dwcdn.net/SD3Ns/"
        />
      </div>
    </section>
  );
}

export default DemTrends;
